
import { Component, Mixins } from 'vue-property-decorator';

import { ListingFilter } from 'client-website-ts-library/filters';
import {
  ListingStatus,
  ListingCategory,
} from 'client-website-ts-library/types';

import {
  ServiceManager,
  ServiceType,
  Firebase,
} from 'client-website-ts-library/services';

import { View } from 'client-website-ts-library/plugins';

import Listings from '../components/Listings.vue';
import SideBySide from '../components/Layout/SideBySide.vue';
import ItemCycler from '../components/Cycler/ItemCycler.vue';

import { ItemCyclerSlide, ItemCyclerImageSlide } from '../app_code/ItemCycler';
import ListingSearch from '../components/ListingSearch.vue';

@Component({
  components: {
    Listings,
    ListingSearch,
    SideBySide,
    ItemCycler,
  },
})
export default class Home extends Mixins(View) {
  private listingFilter = new ListingFilter({
    Statuses: [
      ListingStatus.Current,
      ListingStatus.UnderContract,
    ],
    Categories: [
      ListingCategory.ResidentialSale,
      ListingCategory.ResidentialLand,
    ],
    PageSize: 12,
  });

  private cyclerImages: ItemCyclerSlide[] = [
    new ItemCyclerImageSlide({ source: '/assets/images/home/slider/1', formats: ['avif', 'webp', 'jpg'], fallback: 'jpg' }),
    new ItemCyclerImageSlide({ source: '/assets/images/home/slider/2', formats: ['avif', 'webp', 'jpg'], fallback: 'jpg' }),
    new ItemCyclerImageSlide({ source: '/assets/images/home/slider/3', formats: ['avif', 'webp', 'jpg'], fallback: 'jpg' }),
  ];

  registerFCM(): void {
    const fcm = ServiceManager.Get(ServiceType.Firebase) as Firebase;

    fcm.Subscribe().then((token: string) => {
      console.log(token, this);
    });
  }
}
